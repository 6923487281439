import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./components/Contact/Contact";
import Homepage from "./components/Homepage/Homepage.js";
import About from "./components/About/About";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ManualSoftware from "./components/Service/Functional/ManualSoftware";
import MobileTesting from "./components/Service/Functional/MobileTesting";
import RegressionTesting from "./components/Service/Functional/RegressionTesting";
import Apiweb from "./components/Service/Non-Functional/Apiweb";
import CloudTesting from "./components/Service/Non-Functional/CloudTesting";
import Enterprise from "./components/Service/Non-Functional/Enterprise";
import Globalization from "./components/Service/Non-Functional/Globalization";
import PerformanceTesting from "./components/Service/Non-Functional/PerformanceTesting";
import SecurityTesting from "./components/Service/Non-Functional/SecurityTesting";
import TestData from "./components/Service/Non-Functional/TestData";
import UsabilityTesting from "./components/Service/Non-Functional/UsabilityTesting";
import WebAccessibility from "./components/Service/Non-Functional/WebAccessibility";
import Qualiassist from "./components/Service/Non-Functional/Qualiassist";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Homepage />} exact />
          <Route path="/about-us" element={<About />} exact />

          {/* Services Functional */}
          <Route
            path="/functional/manual-software"
            element={<ManualSoftware />}
            exact
          />
          <Route
            path="/functional/regression-testing"
            element={<RegressionTesting />}
            exact
          />
          <Route
            path="/functional/mobile-testing"
            element={<MobileTesting />}
            exact
          />

          {/* Service Non Functional */}
          <Route path="/non-functional/api-web" element={<Apiweb />} exact />
          <Route
            path="/non-functional/cloud-testing"
            element={<CloudTesting />}
            exact
          />
          <Route
            path="/non-functional/enterprise"
            element={<Enterprise />}
            exact
          />
          <Route
            path="/non-functional/globalization-testing"
            element={<Globalization />}
            exact
          />
          <Route
            path="/non-functional/performance-testing"
            element={<PerformanceTesting />}
            exact
          />
          <Route
            path="/non-functional/qualiassist"
            element={<Qualiassist />}
            exact
          />
          <Route
            path="/non-functional/security-testing"
            element={<SecurityTesting />}
            exact
          />
          <Route
            path="/non-functional/test-data"
            element={<TestData />}
            exact
          />
          <Route
            path="/non-functional/usability-testing"
            element={<UsabilityTesting />}
            exact
          />
          <Route
            path="/non-functional/web-accessibility"
            element={<WebAccessibility />}
            exact
          />

          <Route path="/contact-us" element={<Contact />} exact />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
