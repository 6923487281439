import { NavLink, Link } from "react-router-dom";
import Quali from "../QualiAssist.png";

const Footer = () => {
  return (
    <div>
      <div className="footer wf-section">
        <div className="content-wrapper w-container">
          <div className="footer-top">
            <div>
              <Link to="/" className="footer-brand w-nav-brand">
                <img src={Quali} alt="" width={120} height={100} />
              </Link>
            </div>
            <div className="footer-social-icons">
              <div className="footer-soclal-text">Connect with us</div>
              <a
                rel="noreferrer"
                href="https://www.facebook.com/QualiAssist"
                target="_blank"
                className="social-icon-mini facebook w-inline-block"
              >
                {" "}
              </a>
              <a
                rel="noreferrer"
                href="https://twitter.com/qualiassist"
                target="_blank"
                className="social-icon-mini twittter w-inline-block"
              >
                {" "}
              </a>
              <a
                rel="noreferrer"
                href="https://www.linkedin.com/company/qualiassist/"
                target="_blank"
                className="social-icon-mini linkedin w-inline-block"
              >
                {" "}
              </a>
            </div>
          </div>
          <div className="footer-container">
            <div className="footer-column">
              <div className="footer-left">
                <div className="footer-about-left">
                  <div className="footer-title">About us</div>
                  <div className="top-margin _10-pixels">
                    <p className="text-medium">
                      Qualiassist handles your end-to-end testing so your team
                      can focus on shipping We offer a new approach to quality
                      assurance outsourcing and outstaffing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-expand">
              <div className="menu">
                <div className="footer-title">Menu</div>
                <div className="top-margin _10-pixels">
                  <div className="top-margin _15-pixels">
                    <NavLink
                      exact
                      activeStyle={{ color: "#ff7243" }}
                      to="/"
                      className="footer-menu-link w-inline-block"
                    >
                      <div>Home</div>
                      <div className="footer-underline" />
                    </NavLink>
                  </div>
                  <div className="top-margin _5-pixels">
                    <NavLink
                      exact
                      activeStyle={{ color: "#ff7243" }}
                      to="/about-us"
                      className="footer-menu-link w-inline-block"
                    >
                      <div>About Us</div>
                      <div className="footer-underline" />
                    </NavLink>
                  </div>

                  <div className="top-margin _5-pixels">
                    <NavLink
                      exact
                      activeStyle={{ color: "#ff7243" }}
                      to="/contact-us"
                      className="footer-menu-link w-inline-block"
                    >
                      <div>Contact us</div>
                      <div className="footer-underline" />
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="footer-newsletter">
                <div className="footer-title">Newsletter</div>
                <div className="top-margin _15-pixels">
                  <div className="w-form">
                    <form
                      data-name="Footer Newsletter"
                      name="wf-form-Footer-Newsletter"
                      className="footer-form"
                    >
                      <input
                        type="email"
                        className="footer-field w-input"
                        maxLength={256}
                        name="Email"
                        data-name="Email"
                        placeholder="your@email.com"
                        required
                      />
                      <input
                        type="submit"
                        defaultValue="Join!"
                        data-wait="Please wait..."
                        className="footer-submit-button w-button"
                      />
                    </form>
                    <div className="success-message w-form-done">
                      <img
                        src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfb046872aafd728571603_check.svg"
                        loading="lazy"
                        width={35}
                        alt=""
                      />
                      <div className="top-margin _10-pixels">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                    </div>
                    <div className="error-message w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                  <p className="top-margin _10-pixels text-medium">
                    Contact number : +91 8349348406
                  </p>
                  <p className="top-margin _10-pixels text-medium">
                    Address : Amaryllis Embassy Golf Link Road Bengaluru,
                    Karnataka, India - 560071
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
