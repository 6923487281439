import GoToTop from "../../GotoTop";

const MobileTesting = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage:
            'url("https://images.pexels.com/photos/404280/pexels-photo-404280.jpeg?auto=compress&cs=tinysrgb&w=1600")',
        }}
        id="Top"
        className="content-section services wf-section"
      >
        <div className=" services" />
        <div className="content-wrapper w-container">
          <div style={{ maxWidth: "100%" }} className="hero-services-box">
            <div className="center-content">
              <div className="flex">
                <div
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="services-figure services-page"
                ></div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="on-load"
            >
              <h1 style={{ color: "white" }}>
                NelsonHall Positions Qualiassist As A Leader In 2019 NEAT Charts
                For Mobile Testing Capability
                <br />
                LAUNCH BUG FREE APPLICATIONS WITH CONFIDENCE
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
        className="content-section color-blue wf-section"
      >
        <div className="content-wrapper no-padding w-container">
          <div className="heading-box">
            <div className="top-margin _15-pixels">
              <h3 className="h3-big">Mobile Testing Services</h3>
            </div>
          </div>
          <div
            id="Design-services"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="white-background"
          >
            <div className="rigth-background" />
            <div className="flex">
              <div
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="full-block-image"
              >
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 1919px) 50vw, 645px"
                  width={650}
                  srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg 1300w"
                  alt=""
                />
                <div className="image-bg _12" />
              </div>
              <div className="full-block-text column-left">
                <div className="max-386-pixels">
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="flex align-end"
                  >
                    <div className="service-figure" />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d1baab8603f09cd11ce_Triangle_yellow.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-2 _3-pixels"
                    />
                  </div>
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <div className="top-margin _15-pixels">
                      <p>
                        Mobile devices are inarguably the most disruptive
                        technology invention in recent times and have had an
                        impact on human lives like no o ther. With the number of
                        mobile devices surpassing the number of human beings on
                        earth, making your application mobile-ready is key to
                        keeping today’s digital consumers happy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Coding-services"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="white-background"
          >
            <div className="left-background mobile-reverse" />
            <div className="flex mobile-reverse">
              <div className="full-block-text column-right">
                <div className="max-386-pixels">
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="flex align-end"
                  >
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60be41571194870a763a4015_Triangle_green.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-3 no-margin"
                    />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c17fd85f88f43d5bf14283_Triangle_blue.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-4 _3-pixels"
                    />
                  </div>
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    {/* <div className="top-margin _15-pixels">
                      <h2 className="h2-big">
                      Regression Testing Services :
                      </h2>
                    </div> */}
                    <div className="top-margin _15-pixels">
                      <p>
                        Across enterprises of all sizes, mobile apps have fueled
                        growth in business operations and customer services.
                        However, with the variety of platforms, devices and
                        networks, there is significant infrastructure required
                        for mobile testing services. The testing is either not
                        exhaustive or very costly due to the sub-optimal
                        approach used for testing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="full-block-image"
              >
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 1279px) 50vw, 580px"
                  width={580}
                  srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg 1300w"
                  alt=""
                />
                <div className="image-bg _13" />
              </div>
            </div>
          </div>
          <div
            id="Webflow-design"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="white-background last"
          >
            <div className="rigth-background" />
            <div className="flex">
              <div
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="full-block-image"
              >
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 1919px) 50vw, 645px"
                  width={650}
                  srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg 1300w"
                  alt=""
                />
                <div className="image-bg _14" />
              </div>
              <div className="full-block-text column-left">
                <div className="max-386-pixels">
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="flex align-end"
                  >
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d1baab8603f09cd11ce_Triangle_yellow.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-5 _3-pixels"
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60be41571194870a763a4015_Triangle_green.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-6 _3-pixels"
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <div className="top-margin _15-pixels">
                      <p>
                        Qualiassist provides end-to-end mobile testing services,
                        including functional, security, performance,
                        compatibility, usability and test automation across
                        different devices, models, networks, operating systems,
                        browsers, and locations. You can also engage by
                        leveraging various engagement models like managed
                        testing services, project based engagement, or
                        staffing/T&M. Qualiassist mobile testing services also
                        includes:
                        <ol>
                          <li> Feature Testing </li>

                          <li>Sequence Testing </li>

                          <li> Content Testing</li>

                          <li> Interface Testing </li>

                          <li> Audio testing </li>

                          <li> Installation Testing </li>

                          <li> Error Handling Verification </li>

                          <li> Visual Testing </li>

                          <li> Network testing </li>

                          <li> Location Testing </li>

                          <li> Interruption Testing </li>
                        </ol>
                        We do specific functionality checks to ensure the
                        applications works flawlessly across mobile devices :
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        href="/"
        style={{
          display: "none",
          transform:
            "translate3d(0px, 130%, 0px) scale3d(0.2, 0.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
        className="go-up-button w-inline-block"
      >
        {" "}
      </a>
      <GoToTop />
    </div>
  );
};

export default MobileTesting;
