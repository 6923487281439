import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

const Take = () => {
  const [isSending, updateIsSending] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleInput = (e) => {
    const copyFormData = { ...formData };
    copyFormData[e.target.name] = e.target.value;
    setFormData(copyFormData);
  };
  async function handleSubmit(event) {
    updateIsSending(true);

    // Stop form from submitting
    event.preventDefault();

    const { name, email } = formData;

    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/vikasqualimatrix/google_sheets/KHEWtiuKBdbxJtem?tabId=subscribing",
        {
          method: "post",
          body: JSON.stringify([[new Date().toLocaleString(), name, email]]),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setMessage("Success");
        const form = document.querySelector("form#contactForm");
        form.reset();
        updateIsSending(false);

        // Display the alert
        alert("Thank you subscribing newsletter.");

        // Clear the message when the user closes the alert
        setMessage("");
      } else {
        // console.error("Error:", error);
        setMessage("Error");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Error");
    }
  }
  return (
    <div>
      <div className="content-section-colored cta wf-section">
        <div className="top-white tablet-home"></div>
        <div className="bottom-cta">
          <div className="flex">
            <div className="grid-picture-3"></div>
          </div>
        </div>
        <div className="content-wrapper w-container">
          <div className="cta-flex">
            <div className="cta-left-column">
              <div className="max-440-pixels">
                <h2 className="h3-big">
                  Take your business to the next level—
                </h2>

                <div className="top-margin _30-pixels">
                  <div className="cta-form w-form">
                    <form
                      data-name="CTA Newsletter"
                      name="wf-form-CTA-Newsletter"
                      id="wf-form-CTA-Newsletter"
                      className="form"
                      method="POST"
                      onSubmit={handleSubmit}
                      action="/"
                    >
                      <input
                        type="text"
                        className="name-input w-input"
                        maxlength="256"
                        name="name"
                        data-name="Name"
                        placeholder="Your Name"
                        required=""
                        onChange={handleInput}
                      />
                      <input
                        type="email"
                        className="email-input w-input"
                        name="email"
                        data-name="Email"
                        placeholder="Your Email"
                        required=""
                        onChange={handleInput}
                      />
                      <button
                        type="submit"
                        className="newsletter-submit-button w-button"
                      >
                        {isSending === false && "Join!"}
                        {isSending === null && "Join!"}
                        {isSending === true && "Joining.."}
                      </button>
                      {/* <input
                        type="submit"
                        value="Join!"
                        data-wait="Please wait..."
                        className="newsletter-submit-button w-button"
                      /> */}
                    </form>
                    <div className="success-message w-form-done">
                      <img
                        src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfb046872aafd728571603_check.svg"
                        loading="lazy"
                        width="35"
                        alt=""
                      />
                      <div className="top-margin _10-pixels">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                    </div>
                    <div className="error-message w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cta-right-column">
              <img
                src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bee72881ff69122eefac86_Home_CTA_girl.png"
                loading="lazy"
                width="427"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 60vw, (max-width: 991px) 47vw, 427px"
                srcset="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bee72881ff69122eefac86_Home_CTA_girl-p-500.png 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bee72881ff69122eefac86_Home_CTA_girl.png 854w"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Take;
