import React from "react";

const Knowmore = () => {
  return (
    <div className="on-load-5 style5">
      <div id="Intro" className="content-section intro wf-section">
        <div className="content-wrapper w-container">
          <div className="heading-box">
            {/* <div className="tag">welcome</div> */}
            <div className="top-margin _15-pixels">
              <h2 className="h2-big">Know more about us</h2>
            </div>
          </div>
          <div className="flex-justify-center">
            <div className="intro-box">
              <div className="figure-interaction no-margin">
                <div className="left"></div>
                <div className="right"></div>
                <div className="absolute-flex-center">
                  <div className="circle"></div>
                </div>
              </div>
              <div>
                <div className="top-margin _15-pixels">
                  <a id="About-us" href="#About-us" className="h5-small-link">
                    About us
                  </a>
                </div>
                <div className="top-margin _5-pixels">
                  <p>
                    Proactive, creative & thirst of knowledge experts, who are
                    deeply rooted with user centric approach to deliver the best
                    application quality with seamless user experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="intro-box">
              <div className="flex">
                <div className="figure-interaction-2">
                  <div className="full green"></div>
                </div>
                <div className="figure-interaction-3 no-margin">
                  <div className="full yellow">
                    <div className="absolute-flex-center">
                      <div className="circle green"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="top-margin _15-pixels">
                  <a
                    id="About-us"
                    href="#Our-Services"
                    className="h5-small-link"
                  >
                    Our Services
                  </a>
                </div>
                <div className="top-margin _5-pixels">
                  <p>
                    Our software testing services covers the wide spectrum of
                    the quality assurance and comprise of QA strategy & QA
                    process to boost the overall quality of the application.
                  </p>
                </div>
              </div>
            </div>
            <div className="intro-box last">
              <div className="figure-interaction-4 no-margin">
                <div className="left"></div>
                <div className="right yellow"></div>
              </div>
              <div>
                <div className="top-margin _15-pixels">
                  <a id="About-us" href="#Works" className="h5-small-link">
                    Our Works
                  </a>
                </div>
                <div className="top-margin _5-pixels">
                  <p>
                    Whether it's a desktop, mobile or any next-gen based
                    application our highly qualified software testing experts
                    help you out to get more room for optimizing the products
                    quality with ROI.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Knowmore;
