import React from 'react'
import GoToTop from '../GotoTop'
import Believe from './Believe'
// import Featured from './Featured'
import Header from './Header'
import Imagecontainer from './Imagecontainer'
import ImageContainer2 from './ImageContainer2'
import Imagecontainer3 from './Imagecontainer3'
import Knowmore from './Knowmore'
import News from './News'
// import See from './See'
import Take from './Take'
import Verticals from './Verticals'

const Homepage = () => {
    return (
        <div>
          
            <Header />
            <Knowmore />
            <Imagecontainer />
            <ImageContainer2 />
            <Imagecontainer3 />
            <News />
            <Verticals />
            <Believe />
            <Take />
            <GoToTop />
        </div>
    )
}

export default Homepage
