import React from "react";

const Imagecontainer = () => {
  return (
    <div>
      <div className="content-section double wf-section">
        <div className="content-wrapper w-container">
          <div className="flex">
            <div className="half-column-center">
              <div className="max-386-pixels">
                <div className="flex">
                  <div className="semi-circle"></div>
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d1baab8603f09cd11ce_Triangle_yellow.png"
                    loading="lazy"
                    width="20"
                    alt=""
                    className="right-margin _3-pixels"
                  />
                </div>
                <div className="top-margin _15-pixels">
                  <h2 className="h3-big">We solve quality problems</h2>
                </div>

                <div className="top-margin _5-pixels">
                  <p>
                    Dedicated Teams For QA testing to deliver reliable, scalable
                    & robust applications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Imagecontainer;
