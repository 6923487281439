import React from "react";

const Believe = () => {
  return (
    <div>
      <div
        id="Gallery"
        data-w-id="799c61e8-1417-af79-031c-e52cffead1b6"
        className="content-section-colored color-orange wf-section"
      >
        <div className="content-wrapper w-container">
          <div className="flex">
            <div className="half-column-right">
              <div
                data-w-id="799c61e8-1417-af79-031c-e52cffead1ba"
                className="max-386-pixels"
              >
                <h2 className="h3-big white">
                  Our team believes you deserve only the best
                </h2>
              </div>
            </div>
            <div className="grid-container">
              <div className="flex-relative">
                <div
                  data-w-id="799c61e8-1417-af79-031c-e52cffead1c5"
                  className="ui-mobile"
                >
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd87bdd2ea40c45b5ff451_Grid_bg.jpg"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 43vw, (max-width: 991px) 41vw, (max-width: 1919px) 39vw, 505px"
                    width="505"
                    srcset="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd87bdd2ea40c45b5ff451_Grid_bg-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd87bdd2ea40c45b5ff451_Grid_bg-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd87bdd2ea40c45b5ff451_Grid_bg.jpg 1010w"
                    alt=""
                  />
                  <div className="ui-mobile-container"></div>
                  <div className="browser-dots">
                    <div className="dot"></div>
                    <div className="dot yellow"></div>
                    <div className="dot green"></div>
                  </div>
                </div>
                <div className="grid-gallery">
                  <div
                    data-w-id="8b379659-ad7f-e184-d181-bedbe94d4b98"
                    className="gallery-grid"
                  >
                    <a href="/" className="grid-item w-inline-block w-lightbox">
                      <img
                        src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd893f7fc056cebb749bb2_Home_grid_1_5_6.jpg"
                        loading="lazy"
                        width="199"
                        alt=""
                      />
                      <div className="image-bg grid"></div>
                      <div className="play-container">
                        <img
                          src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd8a5472a683cf4cac78ae_tocar.svg"
                          loading="lazy"
                          width="31"
                          alt=""
                          className="play-button-size"
                        />
                      </div>
                      <script type="application/json" className="w-json">
                        {}
                      </script>
                    </a>
                    <a href="/" className="grid-item w-inline-block w-lightbox">
                      <img
                        src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd8b5d72a6837534ac7c2d_Home_grid_2_3_5.jpg"
                        loading="lazy"
                        width="199"
                        alt=""
                      />
                      <div className="image-bg grid-2"></div>
                      <script type="application/json" className="w-json">
                        {}
                      </script>
                    </a>
                  </div>
                  <div
                    data-w-id="d6cbdbbc-feb2-1631-600f-4bee7288ea30"
                    className="gallery-grid"
                  >
                    <a href="/" className="grid-item w-inline-block w-lightbox">
                      <img
                        src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd8b5d72a6837534ac7c2d_Home_grid_2_3_5.jpg"
                        loading="lazy"
                        width="199"
                        alt=""
                      />
                      <div className="image-bg grid-3"></div>
                      <script type="application/json" className="w-json">
                        {}
                      </script>
                    </a>
                    <a href="/" className="grid-item w-inline-block w-lightbox">
                      <img
                        src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd893f7fc056cebb749bb2_Home_grid_1_5_6.jpg"
                        loading="lazy"
                        width="199"
                        alt=""
                      />
                      <div className="image-bg grid-4"></div>
                      <script type="application/json" className="w-json">
                        {}
                      </script>
                    </a>
                  </div>
                  <div
                    data-w-id="98ed779c-2d23-772d-fc79-3f34245dfb79"
                    className="gallery-grid"
                  >
                    <a href="/" className="grid-item w-inline-block w-lightbox">
                      <img
                        src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd8b5d72a6837534ac7c2d_Home_grid_2_3_5.jpg"
                        loading="lazy"
                        width="199"
                        alt=""
                      />
                      <div className="image-bg grid-5"></div>
                      <script type="application/json" className="w-json">
                        {}
                      </script>
                    </a>
                    <a href="/" className="grid-item w-inline-block w-lightbox">
                      <img
                        src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd893f7fc056cebb749bb2_Home_grid_1_5_6.jpg"
                        loading="lazy"
                        width="199"
                        alt=""
                      />
                      <div className="image-bg grid-6"></div>
                      <div className="play-container">
                        <img
                          src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd8a5472a683cf4cac78ae_tocar.svg"
                          loading="lazy"
                          width="31"
                          alt=""
                          className="play-button-size"
                        />
                      </div>
                      <script type="application/json" className="w-json">
                        {}
                      </script>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Believe;
