import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Quali from "../QualiAssist.png";
const NavBar = () => {
  return (
    <div>
      <div
        data-w-id="258c75bb-ec03-b646-1408-65630187dda7"
        className="navigation"
        style={{
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
      >
        <div
          data-w-id="258c75bb-ec03-b646-1408-65630187dda8"
          className="preloader"
          style={{ height: "0vh", display: "none" }}
        >
          <img
            src={Quali}
            width={120}
            data-w-id="258c75bb-ec03-b646-1408-65630187dda9"
            alt=""
            style={{ opacity: 1 }}
          />
        </div>

        <div
          data-collapse="medium"
          data-animation="default"
          data-duration={400}
          role="banner"
          className="navbar w-nav"
        >
          <div className="nav-content-wrapper w-container">
            <NavLink
              to="/"
              aria-current="page"
              className="brand w-nav-brand w--current"
              aria-label="home"
            >
              <img src={Quali} alt="" width={200} />
            </NavLink>
            <nav role="navigation" className="nav-menu w-nav-menu">
              <div className="left-nav-menu">
                <div
                  data-hover="true"
                  data-delay={0}
                  data-w-id="258c75bb-ec03-b646-1408-65630187ddb7"
                  className="category-dropdown w-dropdown"
                  style={{ maxWidth: "1440px" }}
                ></div>
              </div>
              <div className="button-align-center">
                <div
                  data-hover="true"
                  data-delay={0}
                  data-w-id="258c75bb-ec03-b646-1408-65630187ddfa"
                  className="nav-dropdown w-dropdown"
                  style={{ maxWidth: "1440px" }}
                ></div>
                <NavLink
                  exact
                  to="/"
                  data-w-id="258c75bb-ec03-b646-1408-65630187de10"
                  className="navlink w-inline-block"
                  activeStyle={{ color:'#ff7243' }} 
                >
                  <div>Home</div>
                  <div
                    className="underline-hover mobile-hide"
                    style={{ width: "0%", height: "1px" }}
                  />
                </NavLink>
                <NavLink
                  exact
                  to="/about-us"
                  data-w-id="258c75bb-ec03-b646-1408-65630187de0c"
                  className="navlink w-inline-block"
                  activeStyle={{ color:'#ff7243' }} 
                >
                  <div>About Us</div>
                  <div
                    className="underline-hover mobile-hide"
                    style={{ width: "0%", height: "1px" }}
                  />
                </NavLink>

                <div
                  data-hover="true"
                  data-delay={0}
                  data-w-id="258c75bb-ec03-b646-1408-65630187ddfa"
                  className="nav-dropdown w-dropdown"
                  style={{ maxWidth: "1440px" }}
                >
                  <div
                    className="dropdown-toggle w-dropdown-toggle"
                    id="w-dropdown-toggle-1"
                    aria-controls="w-dropdown-list-1"
                    aria-haspopup="menu"
                    aria-expanded="false"
                    role="button"
                    tabIndex={0}
                  >
                    <div
                      className="mini-arrow w-icon-dropdown-toggle"
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                      aria-hidden="true"
                    />
                    <div className="inline-block">Services</div>
                  </div>
                  <nav
                    className="dropdown-list w-dropdown-list"
                    style={{
                      transform:
                        "translate3d(0px, 20px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    id="w-dropdown-list-1"
                    aria-labelledby="w-dropdown-toggle-1"
                  >
                    <h8>Functional Services</h8>
                    <Link
                      to="/functional/manual-software"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Manual Software Testing
                    </Link>
                    <Link
                      to="/functional/regression-testing"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Regression Testing
                    </Link>

                    <Link
                      to="/functional/mobile-testing"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Mobile Testing
                    </Link>

                    <h8>Non Functional Services</h8>
                    <Link
                      to="/non-functional/performance-testing"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Performance Testing
                    </Link>
                    <Link
                      to="/non-functional/security-testing"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Security Testing
                    </Link>
                    <Link
                      to="/non-functional/usability-testing"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Usability Testing
                    </Link>
                    <Link
                      to="/non-functional/web-accessibility"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Web Accessibility Testing
                    </Link>
                    <Link
                      to="/non-functional/cloud-testing"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Cloud Testing
                    </Link>
                    <Link
                      to="/non-functional/api-web"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      API and Web Services Testing
                    </Link>
                    <Link
                      to="/non-functional/globalization-testing"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Globalization & Localization Testing
                    </Link>
                    <Link
                      to="/non-functional/test-data"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Test Data Management
                    </Link>
                    <Link
                      to="/non-functional/enterprise"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Enterprise Applications Quality Assurance
                    </Link>
                    <Link
                      to="/non-functional/qualiassist"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      Qualiassist Differentiators
                    </Link>
                  </nav>
                </div>

                <NavLink
                  exact
                  to="/contact-us"
                  data-w-id="03ac9abf-55e2-970a-1ab9-7e0446184de9"
                  className="navlink last w-inline-block"
                  activeStyle={{ color:'#ff7243' }} 
                >
                  <div>Contact Us</div>
                  <div
                    className="underline-hover mobile-hide"
                    style={{ width: "0%", height: "1px" }}
                  />
                </NavLink>
              </div>
            </nav>
            <div className="nav-container">
              <div className="nav-flex">
                <div
                  className="menu-button w-nav-button"
                  style={{ WebkitUserSelect: "text" }}
                  aria-label="menu"
                  role="button"
                  tabIndex={0}
                  aria-controls="w-nav-overlay-0"
                  aria-haspopup="menu"
                  aria-expanded="false"
                >
                  <div className="icon w-icon-nav-menu" />
                </div>
                <a
                  href="mailto:digital@qualiassist.com?subject = feedback & body = Message"
                  data-w-id="258c75bb-ec03-b646-1408-65630187de1c"
                  className="contact-form w-inline-block"
                > </a>
              </div>
            </div>
          </div>
          <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
