import React from "react";

const Header = () => {
  return (
    <div>
      <div id="Top" className="content-section wf-section">
        <div className="content-wrapper no-padding w-container">
          <div className="flex">
            <div className="hero-left">
              <div className="hero-text-box">
                <div>
                  <div className="flex">
                    <div
                      style={{
                        transform:
                          "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transformStyle: "preserve-3d",
                      }}
                      className="hero-figure small"
                    >
                      <div className="full color-purple"></div>
                      <div className="absolute-flex-center">
                        <div className="circle medium"></div>
                      </div>
                    </div>
                    <div
                      style={{
                        transform:
                          "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transformStyle: "preserve-3d",
                      }}
                      className="hero-figure-2 small"
                    >
                      <div className="quarter-circle"></div>
                    </div>
                  </div>
                  <div
                    style={{
                      transform:
                        "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transformStyle: "preserve-3d",
                    }}
                    className="on-load"
                  >
                    {/* <h1 className="hero-h1">BUILDING QUALITY</h1> */}
                    <h1 className="hero-h1"> Ensuring the Products Quality</h1>
                    <p>
                    Qualiassist offers a wide range of software testing 
                    services for all digital solutions, products & games 
                    to deliver a seamless bug free experience. The deep 
                    skilled QA professionals understand all business & 
                    customers aspects by well documented bugs tracking & 
                    reporting tools.
                    </p>
                  </div>
                  <div className="half-column-center"></div>
                  <div className="top-margin _20-pixels">
                    <div
                      style={{
                        transform:
                          "translate3d(0, 80px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transformStyle: "preserve-3d",
                      }}
                      className="on-load-2"
                    >
                      <a href="#Intro" className="button w-button">
                        get started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-right-column">
              <div
                style={{
                  transform:
                    "translate3d(0, 15px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transformStyle: "preserve-3d",
                }}
                className="hero-main-image"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
