import React from "react";

const ImageContainer2 = () => {
  return (
    <div>
      <div className="content-section-colored wf-section">
        <div className="content-wrapper w-container">
          <div className="inner-wrapper">
            <div className="flex">
              <div className="column-justify-end">
                <div className="flex-relative tablet-height">
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2.jpg"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 47vw, (max-width: 1279px) 48vw, 500px"
                    width="580"
                    srcset="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2.jpg 1160w"
                    alt=""
                  />
                  <div className="image-bg _2"></div>
                  <div className="top-left-absolute">
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd116cf127301826117e6a_Triangle_offset.png"
                      loading="lazy"
                      width="128.5"
                      alt=""
                      className="triangle"
                    />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10eb10c17600182abf42_Home_triangle_2.png"
                      loading="lazy"
                      width="115.5"
                      alt=""
                      className="triangle-pink"
                    />
                  </div>
                </div>
              </div>
              <div className="half-column-left mobile-margin">
                <div className="max-386-pixels">
                  <h2 className="h3-big">
                    We are a Quality service agency that builds amazing
                    products.
                  </h2>

                  <div className="top-margin _5-pixels">
                    <p>
                      Serve & ensure that products are designed & developed as
                      per users & business needs with minimal errors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageContainer2;
