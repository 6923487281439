import GoToTop from "../../GotoTop";

const ManualSoftware = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage:
            'url("https://images.pexels.com/photos/1181675/pexels-photo-1181675.jpeg?auto=compress&cs=tinysrgb&w=1600")',
        }}
        id="Top"
        className="content-section services wf-section"
      >
        <div className=" services" />
        <div className="content-wrapper w-container">
          <div style={{ maxWidth: "100%" }} className="hero-services-box">
            <div className="center-content">
              <div className="flex">
                <div
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="services-figure services-page"
                ></div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="on-load"
            >
              <h1 style={{ color: "white" }}>
                Achieve Functionally Compliant Applications With Our Manual
                Testing Services
                <br />
                LAUNCH BUG FREE APPLICATIONS WITH CONFIDENCE
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
        className="content-section color-blue wf-section"
      >
        <div className="content-wrapper no-padding w-container">
          <div className="heading-box">
            <div className="top-margin _15-pixels">
              <h3 className="h3-big">Manual Software Testing Services</h3>
            </div>
          </div>
          <div
            id="Design-services"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="white-background"
          >
            <div className="rigth-background" />
            <div className="flex">
              <div
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="full-block-image"
              >
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 1919px) 50vw, 645px"
                  width={650}
                  srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg 1300w"
                  alt=""
                />
                <div className="image-bg _12" />
              </div>
              <div className="full-block-text column-left">
                <div className="max-386-pixels">
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="flex align-end"
                  >
                    <div className="service-figure" />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d1baab8603f09cd11ce_Triangle_yellow.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-2 _3-pixels"
                    />
                  </div>
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <div className="top-margin _15-pixels">
                      <p>
                        As the applications and systems become more complex,
                        enterprises face unique challenges in functional testing
                        to achieve the ‘perfect’ production quality for
                        software. With robust testing processes and technology
                        experience, corporations need strong domain skills for
                        performing effective functional testing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Coding-services"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="white-background"
          >
            <div className="left-background mobile-reverse" />
            <div className="flex mobile-reverse">
              <div className="full-block-text column-right">
                <div className="max-386-pixels">
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="flex align-end"
                  >
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60be41571194870a763a4015_Triangle_green.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-3 no-margin"
                    />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c17fd85f88f43d5bf14283_Triangle_blue.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-4 _3-pixels"
                    />
                  </div>
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <div className="top-margin _15-pixels">
                      <p>
                        Qualiassist brings a unique blend of testing processes,
                        tools and technology expertise, and domain knowledge to
                        ensure that the product is delivered without defects in
                        an accelerated time frame. Qualiassist provides end to
                        end manual testing services for your functional testing
                        needs with a foundation of matured test processes,
                        in-house accelerators, and experience of all
                        industry-leading functional testing tools.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="full-block-image"
              >
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 1279px) 50vw, 580px"
                  width={580}
                  srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg 1300w"
                  alt=""
                />
                <div className="image-bg _13" />
              </div>
            </div>
          </div>
          <div
            id="Webflow-design"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="white-background last"
          >
            <div className="rigth-background" />
            <div className="flex">
              <div
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="full-block-image"
              >
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 1919px) 50vw, 645px"
                  width={650}
                  srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg 1300w"
                  alt=""
                />
                <div className="image-bg _14" />
              </div>
              <div className="full-block-text column-left">
                <div className="max-386-pixels">
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="flex align-end"
                  >
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d1baab8603f09cd11ce_Triangle_yellow.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-5 _3-pixels"
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60be41571194870a763a4015_Triangle_green.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-6 _3-pixels"
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <div className="top-margin _15-pixels">
                      <p>
                        We undertake the complete responsibility for the testing
                        activities from creating an optimized test plan to post
                        production support. We adopt a user-centric testing
                        approach, delivering continuous value improvement in our
                        engagements. We ensure complete traceability of the
                        business requirements to the test cases designed and
                        executed for extensive test coverage. As a professional
                        company for manual testing, we provide extensive
                        reporting with customized dashboards at regular
                        intervals to keep you on top of the testing progress and
                        defects.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        href="/"
        style={{
          display: "none",
          transform:
            "translate3d(0px, 130%, 0px) scale3d(0.2, 0.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
        className="go-up-button w-inline-block"
      >
        {" "}
      </a>
      <GoToTop />
    </div>
  );
};

export default ManualSoftware;
