import GoToTop from "../../GotoTop";

const CloudTesting = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage:
            'url("https://images.pexels.com/photos/5380642/pexels-photo-5380642.jpeg?auto=compress&cs=tinysrgb&w=1600")',
        }}
        id="Top"
        className="content-section services wf-section"
      >
        <div className=" services" />
        <div className="content-wrapper w-container">
          <div style={{ maxWidth: "100%" }} className="hero-services-box">
            <div className="center-content">
              <div className="flex">
                <div
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="services-figure services-page"
                ></div>
              </div>
            </div>
            <div
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="on-load"
            >
              <h1 style={{ color: "white" }}>
                Leverage Effective QA Strategy To Test The Cloud
                <br />
                Achieve Reduced Time-To-Market, Data Security And Apt
                Integrations For Your Cloud Implementations
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
        className="content-section color-blue wf-section"
      >
        <div className="content-wrapper no-padding w-container">
          <div className="heading-box">
            <div className="top-margin _15-pixels">
              <h3 className="h3-big">Cloud Testing Services</h3>
            </div>
          </div>
          <div
            id="Design-services"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="white-background"
          >
            <div className="rigth-background" />
            <div className="flex">
              <div
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="full-block-image"
              >
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 1919px) 50vw, 645px"
                  width={650}
                  srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg 1300w"
                  alt=""
                />
                <div className="image-bg _12" />
              </div>
              <div className="full-block-text column-left">
                <div className="max-386-pixels">
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="flex align-end"
                  >
                    <div className="service-figure" />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d1baab8603f09cd11ce_Triangle_yellow.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-2 _3-pixels"
                    />
                  </div>
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <div className="top-margin _15-pixels">
                      <p>
                        Cloud-based platforms are increasing enterprises’
                        ability to reduce time-to-market drastically, along with
                        eliminating upfront costs. This has led to increased
                        interest in the adoption of cloud-based solutions.
                        However, it brings unique challenges related to data
                        security, privacy, integration and application
                        performance. These require comprehensive cloud-based
                        testing to ensure that your cloud implementations are
                        successful and that you realize complete benefits from
                        the cloud solution.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Coding-services"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="white-background"
          >
            <div className="left-background mobile-reverse" />
            <div className="flex mobile-reverse">
              <div className="full-block-text column-right">
                <div className="max-386-pixels">
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="flex align-end"
                  >
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60be41571194870a763a4015_Triangle_green.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-3 no-margin"
                    />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c17fd85f88f43d5bf14283_Triangle_blue.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-4 _3-pixels"
                    />
                  </div>
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <div className="top-margin _15-pixels">
                      <p>
                        Qualiassist helps you define an effective strategy to
                        test the cloud, so that you can address all the
                        challenges and pitfalls. Software testing in the cloud
                        usually focuses on functional testing of the
                        application, but it also needs a strong emphasis on
                        non-functional and cloud-specific testing. We adopt this
                        end-to-end approach to ensure high quality across all
                        aspects of cloud implementation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="full-block-image"
              >
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 1279px) 50vw, 580px"
                  width={580}
                  srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg 1300w"
                  alt=""
                />
                <div className="image-bg _13" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        href="/"
        style={{
          display: "none",
          transform:
            "translate3d(0px, 130%, 0px) scale3d(0.2, 0.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
        className="go-up-button w-inline-block"
      >
        {" "}
      </a>
      <GoToTop />
    </div>
  );
};

export default CloudTesting;
