import React from "react";

const Imagecontainer3 = () => {
  return (
    <div>
      <div id="Beliefs" className="content-section double wf-section">
        <div className="content-wrapper w-container">
          <div className="flex">
            <div className="half-column-left">
              <div className="max-386-pixels">
                <div className="top-margin _15-pixels">
                  <h1 style={{ backgroundColor: "orange" }} className="h1-big">
                    We Have Passion Of Perfection
                    Honesty and hard work are our beliefs
                  </h1>
                  <h6>We Have Passion Of Perfection</h6>

                  <div className="top-margin _5-pixels">
                    <p>
                      The zeal of being perfect & making the application makes
                      us unique from the crowds. Our expert QA & testing service
                      validates every software aspect to meet the requirement of
                      business needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column-justify-start">
              <div className="flex-relative tablet-height">
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2.jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 991px) 47vw, (max-width: 1279px) 48vw, 580px"
                  width="580"
                  srcset="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2.jpg 1160w"
                  alt=""
                />
                <div className="image-bg _3"></div>
                <div className="absolute-offset">
                  <div className="flex">
                    <div className="figure-offset">
                      <div className="full blue"></div>
                    </div>
                    <div className="figure">
                      <div className="full yellow">
                        <div className="absolute-flex-center">
                          <div className="circle blue"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Imagecontainer3;
