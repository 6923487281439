import React, { useEffect, useState } from "react";
import GoToTop from "../GotoTop";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const [isSending, updateIsSending] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleInput = (e) => {
    const copyFormData = { ...formData };
    copyFormData[e.target.name] = e.target.value;
    setFormData(copyFormData);
  };
  async function handleSubmit(event) {
    updateIsSending(true);

    // Stop form from submitting
    event.preventDefault();

    const { name, email, mobile, subject, companyName, message } = formData;

    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/vikasqualimatrix/google_sheets/KHEWtiuKBdbxJtem?tabId=contact-us",
        {
          method: "post",
          body: JSON.stringify([
            [
              new Date().toLocaleString(),
              name,
              email,
              mobile,
              subject,
              companyName,
              message,
            ],
          ]),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setMessage("Success");
        const form = document.querySelector("form#contactForm");
        form.reset();
        updateIsSending(false);

        // Display the alert
        alert("Thank you for contacting us.");

        // Clear the message when the user closes the alert
        setMessage("");
      } else {
        // console.error("Error:", error);
        setMessage("Error");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Error");
    }
  }

  return (
    <div>
      <div style={{ opacity: 1 }} className="content">
        <div
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="on-load"
        >
          <div className="content-section blog wf-section">
            <div className="hero-blog">
              <div className="content-wrapper w-container">
                <div className="flex">
                  <div className="contact-left">
                    <div className="top-padding">
                      <div
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          opacity: 1,
                          transformStyle: "preserve-3d",
                        }}
                        className="on-load-2"
                      >
                        <div className="flex">
                          <div className="figure-offset contact-us">
                            <div className="full color-blue" />
                          </div>
                          <div className="figure contact-us">
                            <div className="full yellow">
                              <div className="absolute-flex-center">
                                <div className="circle medium-blue" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          opacity: 1,
                          transformStyle: "preserve-3d",
                        }}
                        className="on-load-3"
                      >
                        <h1 className="hero-h1 medium">Let’s get in touch!</h1>
                      </div>
                      <div className="top-margin _15-pixels">
                        <div
                          style={{
                            transform:
                              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                            opacity: 1,
                            transformStyle: "preserve-3d",
                          }}
                          className="on-load-4"
                        >
                          <p>
                            Let us know your details so we can get back to you
                            for discussion!
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          opacity: 1,
                          transformStyle: "preserve-3d",
                        }}
                        className="on-load-5"
                      >
                        <div className="form-block w-form">
                          <form
                            data-name="Form Blog Page"
                            name="wf-form-Form-Blog-Page"
                            method="POST"
                            onSubmit={handleSubmit}
                            //  action="/contact-us"
                          >
                            <input
                              style={{ width: "100%" }}
                              type="text"
                              className="cta-field w-input"
                              maxLength={256}
                              data-name="Name"
                              required
                              name="name"
                              onChange={handleInput}
                              aria-describedby="nameHelp"
                              pattern="[a-zA-Z'-'\s]*"
                              title="Please Enter Alphabets."
                              placeholder="Full Name"
                            />
                            <div className="flex">
                              <input
                                type="email"
                                className="cta-field last w-input"
                                maxLength={256}
                                name="email"
                                data-name="Email"
                                placeholder="Email"
                                onChange={handleInput}
                                required
                              />
                              <input
                                type="tel"
                                maxLength={256}
                                name="mobile"
                                data-name="Mobile"
                                onChange={handleInput}
                                placeholder="Mobile No."
                                className="cta-field w-input"
                              />
                            </div>
                            {/* <select
                              name="Type-of-project"
                              data-name="Type of project"
                              className="select-field w-select"
                            >
                              <option value>Type of Project</option>
                              <option value="Web Design">Web Design</option>
                              <option value="Website Development">
                                Website Development
                              </option>
                              <option value="Marketing">Marketing</option>
                            </select> */}
                            <input
                              style={{ width: "100%" }}
                              type="tel"
                              maxLength={256}
                              name="subject"
                              data-name="Mobile"
                              onChange={handleInput}
                              placeholder="subject"
                              className="cta-field w-input"
                            />
                            <input
                              style={{ width: "100%" }}
                              type="tel"
                              maxLength={256}
                              name="companyName"
                              onChange={handleInput}
                              data-name="Mobile"
                              placeholder="Company Name"
                              className="cta-field w-input"
                            />
                            <textarea
                              data-name="Message"
                              maxLength={5000}
                              onChange={handleInput}
                              placeholder="Describe the project..."
                              name="message"
                              required
                              className="textarea w-input"
                              defaultValue={""}
                            />
                            {/* <input
                              type="submit"
                              defaultValue="Subscribe!"
                              data-wait="Please wait..."
                              className="submit-button w-button"
                            /> */}
                            <button
                              type="submit"
                              className="submit-button w-button"
                              style={{
                                width: "100%",
                              }}
                            >
                              {isSending === false && "Send"}
                              {isSending === null && "Send"}
                              {isSending === true && "Sending"}
                            </button>
                          </form>
                          <div className="success-message w-form-done">
                            <img
                              src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfb046872aafd728571603_check.svg"
                              loading="lazy"
                              width={35}
                              alt=""
                            />
                            <div className="top-margin _10-pixels">
                              <div>
                                Thank you! Your submission has been received!
                              </div>
                            </div>
                          </div>
                          <div className="error-message w-form-fail">
                            <div>
                              Oops! Something went wrong while submitting the
                              form.
                            </div>
                          </div>
                        </div>
                        <div>
                          <p className="text-small">
                            Our numerous clients can confirm that Qualiassist is
                            one the best providers of software testing and
                            quality assurance services.
                            <br />
                            We offer high-quality services at a reasonable
                            price. Contact us and let’s begin improving your
                            software.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      opacity: 1,
                      transformStyle: "preserve-3d",
                    }}
                    className="contact-right"
                  >
                    <div className="image-bg contact-page" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href="/"
          style={{
            display: "none",
            transform:
              "translate3d(0px, 130%, 0px) scale3d(0.2, 0.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          className="go-up-button w-inline-block"
        >
          {" "}
        </a>
      </div>
      {/*[if lte IE 9]><![endif]*/}
      <GoToTop />
    </div>
  );
};

export default Contact;
