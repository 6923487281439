import React from "react";

const Verticals = () => {
  return (
    <div>
      <div id="Verticals" className="content-section double wf-section">
        <div className="content-wrapper w-container">
          <div className="flex">
            <div className="half-column-flex center">
              <div className="picture-grid-container">
                <img
                  className="image"
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc7fc0564c897479b4_Guy_2.png"
                  width="333.5"
                  alt=""
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 24vw, (max-width: 1919px) 25vw, 322.40625px"
                  data-w-id="de243771-58b5-413c-a959-99e364408cf0"
                  loading="lazy"
                  srcset="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc7fc0564c897479b4_Guy_2-p-500.png 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc7fc0564c897479b4_Guy_2.png 667w"
                />
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc8c41c8d86b6d317d_Guy_3.png"
                  loading="lazy"
                  width="333.5"
                  srcset="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc8c41c8d86b6d317d_Guy_3-p-500.png 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc8c41c8d86b6d317d_Guy_3.png 667w"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 24vw, (max-width: 1919px) 25vw, 322.40625px"
                  data-w-id="de243771-58b5-413c-a959-99e364408cf1"
                  alt=""
                />
                <div className="picture-grid-column">
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc45cbc338a0b2bee9_Girl_5.png"
                    loading="lazy"
                    width="333.5"
                    srcset="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc45cbc338a0b2bee9_Girl_5-p-500.png 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc45cbc338a0b2bee9_Girl_5.png 667w"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 25vw, (max-width: 1919px) 26vw, 300px"
                    data-w-id="de243771-58b5-413c-a959-99e364408cf3"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="half-column-left mobile-margin">
              <div
                data-w-id="de243771-58b5-413c-a959-99e364408cf5"
                className="max-386-pixels"
              >
                <div className="top-margin _15-pixels">
                  {/* <h2 className="h3-big">We work in several verticals.</h2> */}
                  <h3 className="h3-big">
                    We Operate In Multiple Verticles of Software Testing
                  </h3>
                  <ul>
                    <li>QA Outsourcing</li>
                    <li>API Integration Testing</li>
                    <li>Automation Testing</li>
                    <li>IoT testing seamless performance</li>
                    <li>Quality Assurance Consultant</li>
                    <li>Testing with business continuity in mind</li>
                    <li>
                      Empowering startups with next gen application testing
                    </li>
                    <li>
                      Quick & transparent collaboration of project management
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verticals;
