import React from "react";

const News = () => {
  return (
    <div>
      <div className="content-section-colored green wf-section">
        <div className="content-wrapper w-container">
          <div className="flex">
            <div className="half-column-right">
              <div className="max-386-pixels">
                {/* <h2 className="h3-big white">
                  Build that idea that you have in your head
                </h2> */}
                <h2 className="h2-big white">
                  Delivering the Right Solutions to Every Business
                </h2>
                <div class="top-margin _15-pixels">
                  <p className="text-white">
                    We understand the uniqueness of each business and its
                    associated customers. Our professional delivers the
                    solutions & approaches which suit the all ecosystem of
                    application.
                  </p>
                </div>
              </div>
            </div>
            <div className="ui-container">
              <div className="flex-relative">
                <div className="ui-mobile green">
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd2297bfc824c48adf7ec2_UI_bg.jpg"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 23vw, (max-width: 1279px) 24vw, 257px"
                    width="257"
                    srcset="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd2297bfc824c48adf7ec2_UI_bg-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd2297bfc824c48adf7ec2_UI_bg.jpg 514w"
                    alt=""
                  />
                  <div className="ui-mobile-container">
                    <div className="left-padding">
                      <div className="title-line short"></div>
                    </div>
                    <div className="ui-block">
                      <div className="ui-item">
                        <div className="ui-image-bg">
                          <img
                            src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd17c73b7575b5f321e587_Home_ui_bg.jpg"
                            loading="lazy"
                            width="79"
                            alt=""
                          />
                          <div className="image-bg ui"></div>
                        </div>
                        <div className="ui-right">
                          <div className="ui-hidden-box">
                            <div className="title-line"></div>
                          </div>
                          <div className="full-width">
                            <div className="ui-hidden-box">
                              <div className="ui-line"></div>
                            </div>
                            <div className="ui-line">
                              <div className="ui-hidden-box"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ui-item">
                        <div className="ui-image-bg">
                          <img
                            src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd17c73b7575b5f321e587_Home_ui_bg.jpg"
                            loading="lazy"
                            width="79"
                            alt=""
                          />
                          <div className="image-bg ui-2"></div>
                        </div>
                        <div className="ui-right">
                          <div className="ui-hidden-box">
                            <div className="title-line"></div>
                          </div>
                          <div className="full-width">
                            <div className="ui-hidden-box">
                              <div className="ui-line"></div>
                            </div>
                            <div className="ui-hidden-box">
                              <div className="ui-line"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ui-item">
                        <div className="ui-image-bg">
                          <img
                            src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd17c73b7575b5f321e587_Home_ui_bg.jpg"
                            loading="lazy"
                            width="79"
                            alt=""
                          />
                          <div className="image-bg ui-3"></div>
                        </div>
                        <div className="ui-right">
                          <div className="ui-hidden-box">
                            <div className="title-line"></div>
                          </div>
                          <div className="full-width">
                            <div className="ui-hidden-box">
                              <div className="ui-line"></div>
                            </div>
                            <div className="ui-hidden-box">
                              <div className="ui-line"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="top-margin _20-pixels">
                      <div className="left-padding">
                        <div className="ui-hidden-box">
                          <div className="title-line short"></div>
                        </div>
                      </div>
                    </div>
                    <div className="top-margin _15-pixels">
                      <div className="ui-item left-padding">
                        <div className="ui-circle"></div>
                        <div className="ui-right">
                          <div className="ui-hidden-box">
                            <div className="title-line"></div>
                          </div>
                          <div className="ui-hidden-box">
                            <div className="top-margin _3-pixels">
                              <div className="ui-line"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="browser-dots">
                    <div className="dot"></div>
                    <div className="dot yellow"></div>
                    <div className="dot green"></div>
                  </div>
                </div>
              </div>
              <div className="ui-rigth">
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd22962c10f58022e3f538_Ui_Right.jpg"
                  loading="lazy"
                  width="256.5"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 23vw, (max-width: 1279px) 24vw, 256.5px"
                  srcset="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd22962c10f58022e3f538_Ui_Right-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd22962c10f58022e3f538_Ui_Right.jpg 513w"
                  alt=""
                />
                <div className="ui-mobile-right">
                  <div className="left-padding">
                    <div className="ui-hidden-box">
                      <div className="title-line short"></div>
                    </div>
                  </div>
                  <div className="top-margin _15-pixels">
                    <div className="move-left">
                      <div className="ui-item landscape">
                        <div className="ui-image-bg full-width">
                          <img
                            src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd26dff868dfc0cfbd82d6_Landscape_bg.jpg"
                            loading="lazy"
                            width="113"
                            alt=""
                          />
                          <div className="image-bg ui-4"></div>
                        </div>
                        <div className="ui-right top-margin">
                          <div className="ui-hidden-box">
                            <div className="title-line"></div>
                          </div>
                          <div className="full-width">
                            <div className="ui-hidden-box">
                              <div className="ui-line"></div>
                            </div>
                            <div className="ui-hidden-box">
                              <div className="ui-line"></div>
                            </div>
                          </div>
                          <div className="top-margin _10-pixels">
                            <div className="heart"></div>
                            <div className="comment"></div>
                          </div>
                        </div>
                      </div>
                      <div className="ui-item landscape">
                        <div className="ui-image-bg full-width">
                          <img
                            src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd26dff868dfc0cfbd82d6_Landscape_bg.jpg"
                            loading="lazy"
                            width="113"
                            alt=""
                          />
                          <div className="image-bg ui-5"></div>
                        </div>
                        <div className="ui-right top-margin">
                          <div className="ui-hidden-box">
                            <div className="title-line"></div>
                          </div>
                          <div className="full-width">
                            <div className="ui-hidden-box">
                              <div className="ui-line"></div>
                            </div>
                            <div className="ui-hidden-box">
                              <div className="ui-line"></div>
                            </div>
                          </div>
                          <div className="top-margin _10-pixels">
                            <div className="heart"></div>
                            <div className="comment"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
