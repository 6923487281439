import { Link } from "react-router-dom";
import GoToTop from "../GotoTop";

const About = () => {
  return (
    <div>
      <div style={{ opacity: 1 }} className="content">
        <div id="Top" className="content-section wf-section">
          <div className="hero-about-us">
            <div className="hero-left about-us">
              <div className="hero-about-box">
                <div>
                  <div className="flex">
                    <div
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      className="about-us-fig"
                    >
                      <div className="half-circle" />
                      <div className="absolute-flex-center">
                        <div className="circle medium-yellow" />
                      </div>
                    </div>
                    <div
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      className="about-figure-2 small"
                    >
                      <div className="full green">
                        <div className="absolute-flex-center">
                          <div className="circle medium" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      opacity: 1,
                      transformStyle: "preserve-3d",
                    }}
                    className="on-load"
                  >
                    <h1 className="hero-h1">
                      Quality Software is the key to Your product Success &
                      Business
                    </h1>
                  </div>
                  <div className="top-margin _20-pixels">
                    <div
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      className="on-load-2"
                    >
                      <Link to="#Intro" className="button w-button">
                        get started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-expand">
              <div className="about-us-absolute">
                <div className="flex-about-us">
                  <div className="hero-column-1">
                    <img
                      className="hero-grid"
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe8879aa88b443c4af7e7_Figure_1.png"
                      width="333.5"
                      alt=""
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      sizes="(max-width: 479px) 235.1875px, (max-width: 767px) 268.796875px, (max-width: 991px) 309.109375px, 336px"
                      loading="lazy"
                      srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe8879aa88b443c4af7e7_Figure_1-p-500.png 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe8879aa88b443c4af7e7_Figure_1.png 667w"
                    />
                    <img
                      className="hero-grid-2"
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc7fc0564c897479b4_Guy_2.png"
                      width="333.5"
                      alt=""
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      sizes="(max-width: 479px) 235.1875px, (max-width: 767px) 268.796875px, (max-width: 991px) 309.109375px, 336px"
                      loading="lazy"
                      srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc7fc0564c897479b4_Guy_2-p-500.png 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc7fc0564c897479b4_Guy_2.png 667w"
                    />
                    <img
                      className="hero-grid-3"
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc8c41c8d86b6d317d_Guy_3.png"
                      width="333.5"
                      alt=""
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      sizes="(max-width: 479px) 235.1875px, (max-width: 767px) 268.796875px, (max-width: 991px) 309.109375px, 336px"
                      loading="lazy"
                      srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc8c41c8d86b6d317d_Guy_3-p-500.png 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc8c41c8d86b6d317d_Guy_3.png 667w"
                    />
                  </div>
                  <div className="hero-column-2">
                    <img
                      className="hero-grid-4"
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe887768907aec4d5ae34_Girl_2.png"
                      width="333.5"
                      alt=""
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      sizes="(max-width: 479px) 235.1875px, (max-width: 767px) 268.796875px, (max-width: 991px) 309.109375px, 336px"
                      loading="lazy"
                      srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe887768907aec4d5ae34_Girl_2-p-500.png 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe887768907aec4d5ae34_Girl_2.png 667w"
                    />
                    <img
                      className="hero-grid-5"
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc45cbc338a0b2bee9_Girl_5.png"
                      width="333.5"
                      alt=""
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      sizes="(max-width: 479px) 235.1875px, (max-width: 767px) 268.796875px, (max-width: 991px) 309.109375px, 336px"
                      loading="lazy"
                      srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc45cbc338a0b2bee9_Girl_5-p-500.png 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd82cc45cbc338a0b2bee9_Girl_5.png 667w"
                    />
                    <img
                      className="hero-grid-6"
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe885a1a87848577ab37b_Guy_3.jpg"
                      width="333.5"
                      alt=""
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      sizes="(max-width: 479px) 235.1875px, (max-width: 767px) 268.796875px, (max-width: 991px) 309.109375px, 336px"
                      loading="lazy"
                      srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe885a1a87848577ab37b_Guy_3-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe885a1a87848577ab37b_Guy_3.jpg 667w"
                    />
                  </div>
                  <div className="hero-column-3">
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe887937e1964903160d9_Girl_5%20(1).png"
                      loading="lazy"
                      width="333.5"
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      alt=""
                      className="hero-grid-7"
                    />
                    <img
                      className="hero-grid-8"
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe8864098cb5b98a66d3c_Girl_4.png"
                      width="333.5"
                      alt=""
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      sizes="(max-width: 479px) 235.1875px, (max-width: 767px) 268.796875px, (max-width: 991px) 309.109375px, 336px"
                      loading="lazy"
                      srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe8864098cb5b98a66d3c_Girl_4-p-500.png 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe8864098cb5b98a66d3c_Girl_4.png 667w"
                    />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfe8878519c4886e9e426b_Guy_5.png"
                      loading="lazy"
                      width="333.5"
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      alt=""
                      className="hero-grid-9"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="on-load-5"
        >
          <div id="Intro" className="content-section bottom-line wf-section">
            <div className="content-wrapper w-container">
              <div className="heading-box">
                <div className="top-margin _15-pixels">
                  <h2 className="h2-big">Why Qualiassist</h2>
                </div>
              </div>
              <div className="flex-justify-center">
                <div className="intro-about-us">
                  <div className="intro-icon-box">
                    <div className="figure-interaction medium-no-margin">
                      <div className="left" />
                      <div className="right yellow" />
                      <div className="absolute-flex-center">
                        <div className="circle small" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h5 className="h5-small">Customer Focus</h5>
                    <div>
                      <p>
                        In the era of continuously changing & challenging
                        business, we always focus on the client’s expectations
                        and provide competent advice & solutions for business
                        goals.
                      </p>
                    </div>
                    {/* <div className="top-margin _5-pixels">
                      <Link to="#About-Us" className="text-link">
                        Learn more
                      </Link>
                    </div> */}
                  </div>
                </div>
                <div className="intro-about-us">
                  <div className="intro-icon-box">
                    <div className="pricing-figure-4 medium-no-margin">
                      <div className="square-scale">
                        <div className="top-square green" />
                        <div className="bottom-square purple" />
                      </div>
                      <div className="absolute-flex-center">
                        <div className="square medium" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h5 className="h5-small">Broad Industry Experience</h5>
                    <div>
                      <p>
                        The custom application operates on multiple domains to
                        ease the complexity & reduce time. We have a wide
                        spectrum of experience to manage every industry need.
                      </p>
                    </div>
                    {/* <div className="top-margin _5-pixels">
                      <Link to="#Features" className="text-link">
                        Learn more
                      </Link>
                    </div> */}
                  </div>
                </div>
                <div className="intro-about-us last">
                  <div className="intro-icon-box">
                    <div className="pricing-figure medium-no-margin">
                      <div className="top light-purple" />
                      <div className="bottom purple" />
                      <div className="absolute-flex-center">
                        <div className="circle small" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h5 className="h5-small">Commitment</h5>
                    <div>
                      <p>
                        We put all our full potential to achieve the customer
                        values to become their reliable technology partners. Our
                        success metrics by customers satisfaction.
                      </p>
                    </div>
                    {/* <div className="top-margin _5-pixels">
                      <Link to="#What-we-do" className="text-link">
                        Learn more
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="About-Us" className="content-section double wf-section">
          <div className="content-wrapper w-container">
            <div className="flex">
              <div className="half-column-left bottom-margin">
                <div className="max-386-pixels">
                  <div>
                    <h2 className="h3-big">
                      Leveraging the digital solution performance with bug-free
                      experience
                    </h2>
                  </div>
                  <div className="top-margin _15-pixels">
                    <p>
                      We ensure the end to end bet solutions to the digital
                      users & businesses to keep them away from every potential
                      error which can impact the brands.
                    </p>
                  </div>
                </div>
              </div>
              <div className="column-justify-start">
                <div className="relative-overflow">
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2.jpg"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 47vw, (max-width: 1279px) 48vw, 580px"
                    width={580}
                    srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10bdc94a48443d81a3ef_Home_2.jpg 1160w"
                    alt=""
                  />
                  <div className="image-bg _4" />
                  <div className="bottom-left-absolute">
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd116cf127301826117e6a_Triangle_offset.png"
                      loading="lazy"
                      width="128.5"
                      alt=""
                      className="triangle"
                    />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10eb10c17600182abf42_Home_triangle_2.png"
                      loading="lazy"
                      width="115.5"
                      alt=""
                      className="triangle-pink"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="What-we-do"
          className="content-section-colored color-purple wf-section"
        >
          <div className="_55-percent-column">
            <div className="image-bg _6" />
          </div>
          <div className="content-wrapper w-container">
            <div className="flex-justify-end">
              <div className="half-white-column">
                <div className="max-386-pixels">
                  <div>
                    <h2 className="h3-big">
                      Dedicated Software testing team for every Business,
                      industry & solutions
                    </h2>
                  </div>
                </div>
                <div className="top-right-absolute">
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c00f35a5fd345555d170ae_About_us_top_triangle.png"
                    loading="lazy"
                    width="115.5"
                    alt=""
                    className="triangle-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section double wf-section">
          <div className="content-wrapper w-container">
            <div className="heading-box">
              {/* <div className="tag green">projects</div> */}
              <div className="top-margin _15-pixels">
                {/* <h3 className="h3-big">How can we help you</h3> */}
              </div>
            </div>
            <div className="flex">
              <div className="half-column-left">
                <div className="max-386-pixels">
                  <div className="top-margin _15-pixels">
                    <h2 className="h3-big">Our Business Approach</h2>
                  </div>
                  <div className="top-margin _15-pixels">
                    <div className="top-margin _25-pixels">
                      <div className="flex">
                        <div className="_47-percent-block">
                          <div className="flex-center center">
                            <div className="figure micro">
                              <div className="full green" />
                            </div>
                            <div className="figure micro">
                              <div className="box color-blue" />
                            </div>
                          </div>
                          <div className="top-margin _15-pixels">
                            <h6 className="h6-small">Fixed Price</h6>
                          </div>
                        </div>
                        <div className="_47-percent-block last">
                          <div className="flex-center center">
                            <div className="figure micro">
                              <div className="box color-blue" />
                            </div>
                            <img
                              src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d1baab8603f09cd11ce_Triangle_yellow.png"
                              loading="lazy"
                              width="28.5"
                              alt=""
                            />
                          </div>
                          <div className="top-margin _15-pixels">
                            <h6 className="h6-small">Team Augmentation</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="color-background">
                <div className="top-white" />
                <div className="flex-relative">
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c013b55f9ab18e7b17eda4_Waiter.png"
                    loading="lazy"
                    width={282}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="top-margin _60-pixels">
              <div className="flex">
                <div className="half-column-left">
                  <div className="max-386-pixels">
                    <div className="top-margin _15-pixels">
                      <h2 className="h3-big">Our Values</h2>
                    </div>
                    <div className="top-margin _15-pixels">
                      <div className="top-margin _25-pixels">
                        <div className="flex">
                          <div className="right-margin">
                            <div className="rounded-checkmark" />
                          </div>
                          <div className="pricing-details-box">
                            <p>
                              <span>
                                <b>Innovations:-</b>
                              </span>{" "}
                              We constantly thrive on new ideas to improvise the
                              efficiency of the product. Brainstorm on new
                              strategies to ease the customers business.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="top-margin _10-pixels">
                        <div className="flex">
                          <div className="right-margin">
                            <div className="rounded-checkmark" />
                          </div>
                          <div className="pricing-details-box">
                            <p>
                              <span>
                                <b>Well Documented Process:-</b>
                              </span>{" "}
                              A documented process allows to monitors, scale &
                              optimize the operation with clean & proper
                              tracking of the work progress.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="color-background yellow">
                  <div className="top-white" />
                  <div className="flex-relative">
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d941b953206845d1fd9_Fitness_girl.png"
                      loading="lazy"
                      width="191.5"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section-colored color-purple wf-section">
          <div className="_55-percent-column right">
            <div className="image-bg _10" />
          </div>
          <div className="content-wrapper w-container">
            <div className="flex-justify-start">
              <div className="half-white-column left-margin">
                <div className="max-386-pixels">
                  <div className="_80-percent-column">
                    <h2 className="h3-big">
                      We are based on the city of Bengaluru Karnataka
                    </h2>
                  </div>
                </div>
                <div className="top-right-absolute" />
                <div className="bottom-left-absolute _2">
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bd10eb10c17600182abf42_Home_triangle_2.png"
                    loading="lazy"
                    width="128.5"
                    alt=""
                    className="triangle bottom-left"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="Features" className="content-section double wf-section">
          <div className="content-wrapper w-container">
            <div className="heading-box">
              <div className="top-margin _15-pixels">
                <h3 className="h3-big">Our Best Features</h3>
              </div>
            </div>
            <div className="features-flex">
              <div className="feature">
                <div className="feature-icon-box">
                  <div className="semi-circle" />
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d1baab8603f09cd11ce_Triangle_yellow.png"
                    loading="lazy"
                    width={20}
                    alt=""
                    className="right-margin _3-pixels"
                  />
                </div>
                <div className="full-expand">
                  <h5 className="h5-small">Well Conceived Plan</h5>
                </div>
              </div>
              <div className="feature">
                <div className="feature-icon-box">
                  <div className="circle-figure green" />
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d1baab8603f09cd11ce_Triangle_yellow.png"
                    loading="lazy"
                    width={20}
                    alt=""
                    className="right-margin _3-pixels"
                  />
                </div>
                <div className="full-expand">
                  <h5 className="h5-small">Effective Use of Technology</h5>
                </div>
              </div>
              <div className="feature">
                <div className="feature-icon-box">
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60be41571194870a763a4015_Triangle_green.png"
                    loading="lazy"
                    width={20}
                    alt=""
                    className="right-margin _3-pixels"
                  />
                  <div className="box small-blue" />
                </div>
                <div className="full-expand">
                  <h5 className="h5-small">
                    Manual & Automation Testing Proficiency
                  </h5>
                </div>
              </div>
              <div className="feature no-margin">
                <div className="feature-icon-box">
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c0254c2174bf48f85e1b32_Triangle_large_blue.png"
                    loading="lazy"
                    width={20}
                    alt=""
                  />
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c024346bb6412e3b47b1d7_Triangle_inverted_yellow.png"
                    loading="lazy"
                    width={20}
                    alt=""
                    className="right-margin _3-pixels"
                  />
                </div>
                <div className="full-expand">
                  <h5 className="h5-small">Friendly Work Environment</h5>
                </div>
              </div>
              <div className="feature">
                <div className="feature-icon-box">
                  <div className="box small-green" />
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c024346bb6412e3b47b1d7_Triangle_inverted_yellow.png"
                    loading="lazy"
                    width={20}
                    alt=""
                    className="right-margin _3-pixels"
                  />
                </div>
                <div className="full-expand">
                  <h5 className="h5-small">Excellent Customers Support</h5>
                </div>
              </div>
              <div className="feature">
                <div className="feature-icon-box">
                  <div className="circle-figure green" />
                  <div className="box small" />
                </div>
                <div className="full-expand">
                  <h5 className="h5-small">Strong Ethics & Values</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-section-colored color-purple wf-section">
          <div className="_55-percent-column">
            <div className="image-bg _11" />
          </div>
          <div className="content-wrapper w-container">
            <div className="flex-justify-end">
              <div className="half-white-column form">
                <div className="max-455-pixels">
                  <div>
                    <div className="flex">
                      <div className="figure medium">
                        <div className="full blue">
                          <div className="absolute-flex-center">
                            <div className="circle yellow-medium" />
                          </div>
                        </div>
                      </div>
                      <div className="figure offset-left">
                        <div className="full yellow" />
                      </div>
                    </div>
                    <div className="top-margin _15-pixels">
                      <h4>Get updates!</h4>
                    </div>
                  </div>
                  <div>
                    <p>Stay updated with our latest news and updates</p>
                  </div>
                  <div className="form-block w-form">
                    <form
                      data-name="CTA Newsletter"
                      name="wf-form-CTA-Newsletter"
                    >
                      <div className="flex">
                        <input
                          type="text"
                          className="cta-field w-input"
                          maxLength={256}
                          name="Name"
                          data-name="Name"
                          placeholder="Your Name"
                          required
                        />
                        <input
                          type="text"
                          maxLength={256}
                          name="Last-Name"
                          data-name="Last Name"
                          placeholder="Last Name"
                          className="cta-field last w-input"
                        />
                      </div>
                      <input
                        type="email"
                        className="cta-field full w-input"
                        maxLength={256}
                        name="Email"
                        data-name="Email"
                        placeholder="Your Email"
                        required
                      />
                      <input
                        type="tel"
                        maxLength={256}
                        name="Mobile"
                        data-name="Mobile"
                        placeholder="Your Mobile"
                        className="cta-field full w-input"
                      />
                      <input
                        type="submit"
                        defaultValue="Subscribe!"
                        data-wait="Please wait..."
                        className="submit-button w-button"
                      />
                    </form>
                    <div className="success-message w-form-done">
                      <img
                        src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60bfb046872aafd728571603_check.svg"
                        loading="lazy"
                        width={35}
                        alt=""
                      />
                      <div className="top-margin _10-pixels">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                    </div>
                    <div className="error-message w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-small">
                      Simply fill the form out and you are ready to receive the
                      latest news directly on your inbox.
                    </p>
                  </div>
                </div>
                <div className="top-right-absolute">
                  <img
                    src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c00f35a5fd345555d170ae_About_us_top_triangle.png"
                    loading="lazy"
                    width="115.5"
                    alt=""
                    className="triangle-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="content-section double wf-section">
          <div className="content-wrapper w-container">
            <div className="heading-box">
              <div className="tag green">Numbers</div>
              <div className="top-margin _15-pixels">
                <h3 className="h3-big">How do we perform</h3>
              </div>
            </div>
            <div className="narrow-wrapper">
              <div className="flex-space">
                <div
                  data-animation="slide"
                  data-duration={500}
                  data-infinite={1}
                  className="numbers w-slider"
                  role="region"
                  aria-label="carousel"
                >
                  <div className="corner-block" />
                  <div className="w-slider-mask" id="w-slider-mask-0">
                    <div
                      className="w-slide"
                      aria-label="1 of 2"
                      role="group"
                      style={{ transform: "translateX(0px)", opacity: 1 }}
                    >
                      <div className="inner-padding">
                        <div className="figure-interaction medium-no-margin">
                          <div className="left green" />
                          <div className="right yellow" />
                        </div>
                        <div className="top-margin _50-pixels">
                          <div className="text-block">87.9%</div>
                          <div className="top-margin _15-pixels">
                            <h6 className="h6-medium">
                              More store
                              <br />
                              online sales
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="slide-numbers w-slide"
                      aria-label="2 of 2"
                      role="group"
                      aria-hidden="true"
                      style={{ transform: "translateX(0px)", opacity: 1 }}
                    >
                      <h6 className="h6-medium" aria-hidden="true">
                        More Online Sales
                      </h6>

                      <div className="top-margin _15-pixels" aria-hidden="true">
                        <p aria-hidden="true">
                          <a
                            href="/contact-us"
                            className="text-link white"
                            tabIndex={-1}
                            aria-hidden="true"
                          >
                            Contact us
                          </a>
                        </p>
                      </div>
                    </div>
                    <div
                      aria-live="off"
                      aria-atomic="true"
                      className="w-slider-aria-label"
                      data-wf-ignore
                    />
                    <div
                      aria-live="off"
                      aria-atomic="true"
                      className="w-slider-aria-label"
                      data-wf-ignore
                    />
                  </div>
                  <div
                    className="display-none w-slider-arrow-left"
                    role="button"
                    tabIndex={0}
                    aria-controls="w-slider-mask-0"
                    aria-label="previous slide"
                  >
                    <div className="w-icon-slider-left" />
                  </div>
                  <div
                    className="right-arrow w-slider-arrow-right"
                    role="button"
                    tabIndex={0}
                    aria-controls="w-slider-mask-0"
                    aria-label="next slide"
                  >
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c0ead7285357edaff9b7b7_flecha-apuntando-a-la-derecha.svg"
                      loading="lazy"
                      width={23}
                      alt=""
                      className="number-arrow"
                    />
                  </div>
                  <div className="display-none w-slider-nav w-round">
                    <div
                      className="w-slider-dot w-active"
                      data-wf-ignore
                      aria-label="Show slide 1 of 2"
                      aria-pressed="true"
                      role="button"
                      tabIndex={0}
                    />
                    <div
                      className="w-slider-dot"
                      data-wf-ignore
                      aria-label="Show slide 2 of 2"
                      aria-pressed="false"
                      role="button"
                      tabIndex={-1}
                    />
                  </div>
                </div>
                <div
                  data-animation="slide"
                  data-duration={500}
                  data-infinite={1}
                  className="numbers w-slider"
                  role="region"
                  aria-label="carousel"
                >
                  <div className="corner-block yellow" />
                  <div className="w-slider-mask" id="w-slider-mask-1">
                    <div
                      className="w-slide"
                      aria-label="1 of 2"
                      role="group"
                      style={{ transform: "translateX(0px)", opacity: 1 }}
                    >
                      <div className="inner-padding">
                        <div className="pricing-figure medium">
                          <div className="top yellow" />
                          <div className="bottom green" />
                        </div>
                        <div className="top-margin _50-pixels">
                          <div className="text-block">27x</div>
                          <div className="top-margin _15-pixels">
                            <h6 className="h6-medium">
                              Average user
                              <br />
                              retention
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="slide-numbers w-slide"
                      aria-label="2 of 2"
                      role="group"
                      aria-hidden="true"
                      style={{ transform: "translateX(0px)", opacity: 1 }}
                    >
                      <h6 className="h6-medium" aria-hidden="true">
                        Average user
                        <br aria-hidden="true" />
                        retention
                      </h6>

                      <div className="top-margin _15-pixels" aria-hidden="true">
                        <p aria-hidden="true">
                          <a
                            href="/contact-us"
                            className="text-link white"
                            tabIndex={-1}
                            aria-hidden="true"
                          >
                            Contact us
                          </a>
                        </p>
                      </div>
                    </div>
                    <div
                      aria-live="off"
                      aria-atomic="true"
                      className="w-slider-aria-label"
                      data-wf-ignore
                    />
                    <div
                      aria-live="off"
                      aria-atomic="true"
                      className="w-slider-aria-label"
                      data-wf-ignore
                    />
                  </div>
                  <div
                    className="display-none w-slider-arrow-left"
                    role="button"
                    tabIndex={0}
                    aria-controls="w-slider-mask-1"
                    aria-label="previous slide"
                  >
                    <div className="w-icon-slider-left" />
                  </div>
                  <div
                    className="right-arrow yellow w-slider-arrow-right"
                    role="button"
                    tabIndex={0}
                    aria-controls="w-slider-mask-1"
                    aria-label="next slide"
                  >
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c0ead7285357edaff9b7b7_flecha-apuntando-a-la-derecha.svg"
                      loading="lazy"
                      width={23}
                      alt=""
                      className="number-arrow"
                    />
                  </div>
                  <div className="display-none w-slider-nav w-round">
                    <div
                      className="w-slider-dot w-active"
                      data-wf-ignore
                      aria-label="Show slide 1 of 2"
                      aria-pressed="true"
                      role="button"
                      tabIndex={0}
                    />
                    <div
                      className="w-slider-dot"
                      data-wf-ignore
                      aria-label="Show slide 2 of 2"
                      aria-pressed="false"
                      role="button"
                      tabIndex={-1}
                    />
                  </div>
                </div>
                <div
                  data-animation="slide"
                  data-duration={500}
                  data-infinite={1}
                  className="numbers last w-slider"
                  role="region"
                  aria-label="carousel"
                >
                  <div className="corner-block pink" />
                  <div className="w-slider-mask" id="w-slider-mask-2">
                    <div
                      className="w-slide"
                      aria-label="1 of 2"
                      role="group"
                      style={{ transform: "translateX(0px)", opacity: 1 }}
                    >
                      <div className="inner-padding">
                        <div className="pricing-figure-4 medium">
                          <div className="square-scale">
                            <div className="top-square yellow" />
                            <div className="bottom-square" />
                          </div>
                        </div>
                        <div className="top-margin _50-pixels">
                          <div className="text-block">+99.9</div>
                          <div className="top-margin _15-pixels">
                            <h6 className="h6-medium">
                              Online fraud
                              <br />
                              prevention
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="slide-numbers w-slide"
                      aria-label="2 of 2"
                      role="group"
                      aria-hidden="true"
                      style={{ transform: "translateX(0px)", opacity: 1 }}
                    >
                      <h6 className="h6-medium" aria-hidden="true">
                        Online fraud
                        <br aria-hidden="true" />
                        prevention
                      </h6>
                      <div
                        aria-hidden="true"
                        data-read-aloud-multi-block="true"
                      ></div>
                      <div className="top-margin _15-pixels" aria-hidden="true">
                        <p aria-hidden="true">
                          <a
                            href="/contact-us"
                            className="text-link white"
                            tabIndex={-1}
                            aria-hidden="true"
                          >
                            Contact us
                          </a>
                        </p>
                      </div>
                    </div>
                    <div
                      aria-live="off"
                      aria-atomic="true"
                      className="w-slider-aria-label"
                      data-wf-ignore
                    />
                    <div
                      aria-live="off"
                      aria-atomic="true"
                      className="w-slider-aria-label"
                      data-wf-ignore
                    />
                  </div>
                  <div
                    className="display-none w-slider-arrow-left"
                    role="button"
                    tabIndex={0}
                    aria-controls="w-slider-mask-2"
                    aria-label="previous slide"
                  >
                    <div className="w-icon-slider-left" />
                  </div>
                  <div
                    className="right-arrow pink w-slider-arrow-right"
                    role="button"
                    tabIndex={0}
                    aria-controls="w-slider-mask-2"
                    aria-label="next slide"
                  >
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c0ead7285357edaff9b7b7_flecha-apuntando-a-la-derecha.svg"
                      loading="lazy"
                      width={23}
                      alt=""
                      className="number-arrow"
                    />
                  </div>
                  <div className="display-none w-slider-nav w-round">
                    <div
                      className="w-slider-dot w-active"
                      data-wf-ignore
                      aria-label="Show slide 1 of 2"
                      aria-pressed="true"
                      role="button"
                      tabIndex={0}
                    />
                    <div
                      className="w-slider-dot"
                      data-wf-ignore
                      aria-label="Show slide 2 of 2"
                      aria-pressed="false"
                      role="button"
                      tabIndex={-1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <a
          href="#Top"
          style={{
            display: "none",
            transform:
              "translate3d(0px, 130%, 0px) scale3d(0.2, 0.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          className="go-up-button w-inline-block w--current"
        >
          {" "}
        </a>
      </div>
      {/*[if lte IE 9]><![endif]*/}
      <GoToTop />
    </div>
  );
};

export default About;
