// import Header from "../Header"

import GoToTop from "../../GotoTop";

const Qualiassist = () => {
  return (
    <div>
      {/* <div style={{backgroundImage: 'url("https://289nff1fvreh39n6837cw2a1-wpengine.netdna-ssl.com/wp-content/uploads/2015/12/services-header-background-for-web.jpg")'}} id="Top" className="content-section services wf-section">
        <div className=" services" />
        <div className="content-wrapper w-container">
          <div style={{maxWidth: '100%'}} className="hero-services-box">
            <div className="center-content">
              <div className="flex">
                <div
                  
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="services-figure services-page"
                >
                  <div className="big-semi-circle">
                    <div className="absolute-flex-center">
                      <div className="circle services" />
                    </div>
                  </div>
                </div>
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c10e3ecf201423a93d6d35_Triangle_orange.png"
                  loading="lazy"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  width={48}
                  
                  alt=""
                  className="services-figure-2"
                />
              </div>
            </div>
            <div
              
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
              className="on-load"
            >
              <h1 className="hero-h1">
              ENTERPRISE APPLICATIONS QUALITY ASSURANCE SERVICES

              </h1>
            </div>
            
          </div>
        </div>
      </div> */}

      <div
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
        className="content-section color-blue wf-section"
      >
        <div className="content-wrapper no-padding w-container">
          <div className="heading-box">
            <div className="top-margin _15-pixels">
              <h3 className="h3-big">Qualiassist Differentiators</h3>
            </div>
          </div>
          <div
            id="Design-services"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="white-background"
          >
            <div className="rigth-background" />
            <div className="flex">
              <div
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="full-block-image"
              >
                <img
                  src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 1919px) 50vw, 645px"
                  width={650}
                  srcSet="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-500.jpeg 500w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-800.jpeg 800w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1)-p-1080.jpeg 1080w, https://assets.website-files.com/60baad29fc862b570a5e83f8/60c185f3be9f2bdda737e26e_Service_bg%20(1).jpg 1300w"
                  alt=""
                />
                <div className="image-bg _12" />
              </div>
              <div className="full-block-text column-left">
                <div className="max-386-pixels">
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="flex align-end"
                  >
                    <div className="service-figure" />
                    <img
                      src="https://assets.website-files.com/60baad29fc862b570a5e83f8/60c01d1baab8603f09cd11ce_Triangle_yellow.png"
                      loading="lazy"
                      width="28.5"
                      alt=""
                      className="service-figure-2 _3-pixels"
                    />
                  </div>
                  <div
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <div className="top-margin _15-pixels">
                      <p>
                        1. Expertise on a wide range of applications, including
                        the web, desktop, mobile, packaged products etc. 2.
                        Support various development methodologies, like Agile,
                        waterfall, DevOps, and Hybrid models and work
                        collaboratively with the development teams. 3. Involved
                        early in the project lifecycle to lower the cost of
                        quality. 4.Testing professionals with comprehensive
                        experience across domains. 5.Test requirement techniques
                        to ensure Bi-Directional traceability. 6. Organized
                        approach to testing and the organization of test cases
                        to promote repeatability. 7. Risk-based Testing
                        Methodology addressing Business, Project, and Technology
                        risks. 8.Formalized defect documentation processes to
                        ensure reproducibility of defects. 9.Expertise on all
                        leading tools for application lifecycle management, test
                        management, defect management, test data management etc.
                        10.Structured project communications, including daily,
                        weekly, and monthly status reports with detailed test
                        execution status and analysis delivered through a
                        customized dashboard.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        href="/"
        style={{
          display: "none",
          transform:
            "translate3d(0px, 130%, 0px) scale3d(0.2, 0.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
        className="go-up-button w-inline-block"
      >
        {" "}
      </a>
      <GoToTop />
    </div>
  );
};

export default Qualiassist;
